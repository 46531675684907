<template>
    <div class="up_content_inner up_scan_reports">
        <div class="up_main_container">
            <div class="up_page_header p-mb-4 p-d-flex p-jc-between">
                <h2 class="up_page_title">View Accesibility Scanning</h2>
                <div class="p-d-flex">
                    <Button type="button" :icon="['pi', progressReport.loadingDownload ? 'pi-spin pi-spinner' : 'pi-file-pdf']" label="Download PDF" class="p-button-primary p-mr-2" @click="downloadReport()" :disabled="progressReport.loadingDownload" />
                    <Button type="button" icon="pi pi-eye" :label="`View ${progressReport.totalViolation} violations`" class="p-button-danger p-mr-2" @click="$router.push({
                        name: 'CustomerViolationReport',
                        params: {
                            uuid: progressReport.uuid
                        },
                    })" />
                    <Button type="button" icon="pi pi-arrow-left" label="Back" class="p-button-primary" @click="$router.push({name: 'CustomerAccessibilityScan'})" />
                </div>
            </div>
            <div v-if="progressReport.loading" class="loading">Loading Reports...</div>
            <div v-if="!progressReport.loading" class="p-grid p-formgrid p-text-center up_scan_typs">
                <div class="p-field p-col-12 p-sm-4 p-md-2">
                    <Knob v-model="progressReport.error" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#d73925" rangeColor="#d7392533" animation />
                    <h5>Errors</h5>
                </div>
                <div class="p-field p-col-12 p-sm-4 p-md-2">
                    <Knob v-model="progressReport.contrastErrors" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#3366cc" rangeColor="#3366cc33" />
                    <h5>Contrast Errors</h5>
                </div>
                <div class="p-field p-col-12 p-sm-4 p-md-2">
                    <Knob v-model="progressReport.alerts" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#f39c12" rangeColor="#f39c1233" />
                    <h5>Alerts</h5>
                </div>
                <div class="p-field p-col-12 p-sm-4 p-md-2">
                    <Knob v-model="progressReport.features" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#008d4c" rangeColor="#008d4c33" :max="500" />
                    <h5>Features</h5>
                </div>
                <div class="p-field p-col-12 p-sm-4 p-md-2">
                    <Knob v-model="progressReport.structuralElements" readonly valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#77c887" rangeColor="#77c88733" :max="500" />
                    <h5>Structural Elements</h5>
                </div>
                <div class="p-field p-col-12 p-sm-4 p-md-2">
                    <Knob v-model="progressReport.aria" readonly  valueTemplate="{value}%" :strokeWidth="5" :size="200" valueColor="#46c1b0" rangeColor="#46c1b033" />
                    <h5>Aria</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userPortalScanedById, userPortalDownloadReport, userPortalWebsiteScan } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import customerLogout from '../../../../helper/userLogout/customerLogout';
import { useRoute } from "vue-router";
import router from "../../../../router/index";
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';
const FileSaver = require('file-saver');

export default {
    name: "ScaningReport",
    setup(){
        //Application store call
        const store = useStore();

        //Route call
        const route = useRoute();

        const progressReport = reactive ({
            loadingDownload: false,
            error: null,
            contrastErrors: null,
            alerts: null,
            features: null,
            structuralElements: null,
            aria: null,
            totalViolation: null,
            uuid: null,
            loading: false
        });

        onBeforeMount(()=>{
            if (Object.keys(route.params).length > 0) {
                progressReport.uuid = route.params.uuid;
            fetchScanReport();
            fetchScanViolationReport();
            } else {
                router.push({name: "CustomerAccessibilityScan"});
            }
        });

        const fetchScanReport = async() =>{
            try{
                progressReport.loading = !progressReport.loading;
                const response = await axios.get( userPortalScanedById, {
                    params:{
                        uuid: progressReport.uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                progressReport.error = response.data.data.errors;
                progressReport.contrastErrors = response.data.data.contrastErrors;
                progressReport.contrastErrors = response.data.data.contrastErrors;
                progressReport.alerts = response.data.data.alerts;
                progressReport.features = response.data.data.features;
                progressReport.structuralElements = response.data.data.structuralElements;
                progressReport.aria = response.data.data.arias;
                progressReport.loading = !progressReport.loading;
            } catch(err){
                if(err.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const fetchScanViolationReport = async() =>{
            try{
                progressReport.loading = !progressReport.loading;
                const response = await axios.get( userPortalWebsiteScan, {
                    params:{
                        uuid: progressReport.uuid
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                progressReport.totalViolation = response.data.data.websitescan[0].totalViolationCount;
                progressReport.loading = !progressReport.loading;
            } catch(err){
                if(err.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const downloadReport = async() => {
            progressReport.loadingDownload = !progressReport.loadingDownload;
            const response = await axios.get( userPortalDownloadReport, {
                responseType: 'arraybuffer',
                params: {
                    uuid : progressReport.uuid
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }, 
            });
            if(response.status === 200) {
                const dirtyFileName = response.headers['content-disposition'];
                const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                var fileName = dirtyFileName.match(regex)[3];
                var blob = new Blob([response.data], { type: 'application/pdf' });
                FileSaver(blob, fileName);
                progressReport.loadingDownload = !progressReport.loadingDownload;
            }
        }

        return {
            progressReport,
            fetchScanReport,
            downloadReport,
            fetchScanViolationReport
        }
    }
}
</script>

<style lang="scss">
.up_scan_typs{
    h5{
        font-size: 15px;
        font-weight: 400;
        text-transform: uppercase;
    }
}
</style>